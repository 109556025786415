import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";

export class TestKillQueen extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Kill king queen';
    }

    init() {
        this.chessMap.moves = [
          new QueenBlack(),
          new QueenBlack(),
          new QueenBlack(),
        ];
        this.chessMap.cells[1][4].figure = undefined;
        this.chessMap.cells[6][3].figure = undefined;
        this.chessMap.cells[2][4].figure = new PawnDark();
        this.chessMap.currentMoveBlack = true;
    }

    check() {
        for (const row of this.chessMap.cells) {
            for (const cell of row) {
                if (cell.figure && cell.figure.type === 'q' && cell.figure.isBlack !== this.chessMap.currentMoveBlack) {
                    return false;
                }
            }
        }

        return true;
    }
}