import {ChessMap} from "../../models/chess.map";
import {Movement} from "../../models/movement";
import {chessAlgo} from "../../algo/chess";
import {randomMove} from "../parts/random-move";
import {getFigurePrice} from "./figure-price";
import {getBeatMap} from "./beat-map";
import {enemyKingDied} from "../true/math";
import {isSorted} from "../parts/price";
import {distanceKingHeightFromStart} from "../parts/distance";
import {countCanMove, countCanMoveDistinct} from "../parts/count";


let results: Movement[] = [];
let mxResult: number = 0;

export let lastMx = '';
export const checkResult = (map: ChessMap, moves: Movement[],
                            types: string[], killed: number[], lastMove: string) => {
    let nwPrice = getPrice(map, moves, types, killed, lastMove);

    if (nwPrice > mxResult) {
        mxResult = nwPrice;
        // lastMx = nwPrice;
        results = [moves[0]];
    } else if (nwPrice === mxResult) {
        results.push(moves[0]);
    }
}

export const prepareKilled = (killed: number) => {
    return (100000 + killed).toString().slice(-4);
}


const killCoef = 10000;
const killLittleCoef = 10;
const killEnKingCoef = killCoef * killCoef;
const beat1 = 100;
const beat2 = 9;
const beat3 = 1;
const attacked1 = -2000;
const attacked2 = -51;
const attacked3 = -1;

const sorted = 10;
const kingPos = -10;
const movesC = 0;

export const getPrice = (map: ChessMap, moves: Movement[],
                         types: string[], killed: number[], lastMove: string): number => {
    let result = 0;

    const enDied = enemyKingDied(map);

    if (enDied) {
        result += killEnKingCoef;
    }

    if (isSorted(types)) {
        result += sorted;
    }

    result += kingPos * distanceKingHeightFromStart(map, map.currentMoveBlack);
    result += movesC * countCanMove(map, map.currentMoveBlack);

    const beatMap = getBeatMap(map);


    for (const kill of killed) {
        result += kill >= 0 ? kill * killCoef : killLittleCoef * (100-kill);
    }

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const columnId of row) {

            const figure = map.cells[rowId][cellId].figure;


            if (figure) {
                const price = getFigurePrice(map, figure.type, rowId, cellId, true);

                if (figure.isBlack === map.currentMoveBlack) {
                    result += (attacked1 * beatMap[rowId][cellId].lvl1
                        + attacked2 * beatMap[rowId][cellId].lvl2
                        + attacked3 * beatMap[rowId][cellId].lvl3) * price;
                } else {
                    result += (beat1 * beatMap[rowId][cellId].lvl1
                        + beat2 * beatMap[rowId][cellId].lvl2
                        + beat3 * beatMap[rowId][cellId].lvl3) * price;
                }


            }


            cellId++;
        }
        rowId++;
    }


    return result;
}

export const getBestMove = (map: ChessMap, lastMove: string): Movement | null => {

    results = [];
    mxResult = -1e9;

    // lvl 1
    const mv1 = chessAlgo.getMoves(map);

    for (const m1 of mv1) {
        const t1 = map.cells[m1.movement.rowFrom][m1.movement.columnFrom].figure?.type as string;
        const tt1 = map.cells[m1.movement.rowTo][m1.movement.columnTo].figure?.type;

        const a1 = tt1 ? (getFigurePrice(map, tt1, m1.movement.rowTo, m1.movement.columnTo) -
            getFigurePrice(map, t1, m1.movement.rowFrom, m1.movement.columnFrom)) + 1 : 0;

        const pos1 = { x: m1.movement.rowTo, y: m1.movement.columnTo };

        m1.move(map);


        checkResult(map, [m1], [t1], [a1], lastMove);

        const mv2 = chessAlgo.getMoves(map);

        for (const m2 of mv2) {
            const t2 = map.cells[m2.movement.rowFrom][m2.movement.columnFrom].figure?.type as string;
            const tt2 = map.cells[m2.movement.rowTo][m2.movement.columnTo].figure?.type;

            const a2 = tt2 ? (getFigurePrice(map, tt2, m2.movement.rowTo, m2.movement.columnTo) -
                getFigurePrice(map, t2, m2.movement.rowFrom, m2.movement.columnFrom)) + 1 : 0;

            const pos2 = { x: m2.movement.rowTo, y: m2.movement.columnTo };

            m2.move(map);

            checkResult(map, [m1, m2], [t1, t2], [a1, a2], lastMove);

            const mv3 = chessAlgo.getMoves(map);

            for (const m3 of mv3) {

                const t3 = map.cells[m3.movement.rowFrom][m3.movement.columnFrom].figure?.type as string;
                const tt3 = map.cells[m3.movement.rowTo][m3.movement.columnTo].figure?.type;

                const a3 = tt3 ? (getFigurePrice(map, tt3, m3.movement.rowTo, m3.movement.columnTo) -
                    getFigurePrice(map, t3, m3.movement.rowFrom, m3.movement.columnFrom))  + 1 : 0;

                const pos3 = { x: m3.movement.rowTo, y: m3.movement.columnTo };
                m3.move(map);

                checkResult(map, [m1, m2, m3], [t1, t2, t3], [a1, a2, a3], lastMove);

                m3.unmove(map);

            }

            m2.unmove(map);
        }

        m1.unmove(map);
    }


    const move = randomMove(results);




    return move;
};