


export const figurePrice: { [key: string]: number } = {
    p: 1,
    b: 4,
    h: 8,
    r: 5,
    q: 9,
    k: 100
};

export enum E_ATTACK_PRICE {
    MORE_RANK = 11000,
    SAME_RANK = 10000,
    KING_ATTACKER = 10000,
    EN_LAST_OR_OUR_NLAST = 100,
    UNDER_ATTACK_MULT = 1,
    KILL_LOWER = 1,
    KING_DEFS = 10,
    SORT_PRICE = 1,
    ROOK_OPEN = 2,
}

export enum E_MOVE_PRICE {
    UNDER_ATTACK_MULT = 10000,
    UNDER_ATTACK_EN_MULT = 150,
    UNDER_ATTACK_MULT2 = 1,
    UNDER_ATTACK_EN_MULT2 = 0.05,
    MOVE_PRICE_DISTINCT = 500,
    MOVE_PRICE = 100,
    MOVE_PRICE_COUNT = 1,
    ROOK_OPEN = 10,
    MOVE_KING_POS = 10001,
    SORT_PRICE = 1,
    SECOND_BEAT_K = 0.1,
    LENGTH_PRICE = 10000,
    KING_DEFS = 150,
}

export enum E_MOVE_BEAT_PRICE {
    UNDER_ATTACK_MULT = 10000,
    UNDER_ATTACK_EN_MULT = 150,
    UNDER_ATTACK_MULT2 = 1,
    UNDER_ATTACK_EN_MULT2 = 0.05,
    MOVE_PRICE_DISTINCT = 500,
    MOVE_PRICE = 100,
    MOVE_PRICE_COUNT = 1,
    ROOK_OPEN = 10,
    MOVE_KING_POS = 10001,
    SORT_PRICE = 1,
    SECOND_BEAT_K = 0.1,
    LENGTH_PRICE = 10000,
    KING_DEFS = 150,
    QUEEN_MN = 100,
    KILL_ATTACKER = 10000,
    KILL_LOW = 1000,
    KILL_SAME = 500000,
    KILL_HARD = 1000000,
    KILL_LAST = 30000,
    ATTACKER_STREAM  = 40000,
}


export const isSorted = function(keys: string[]): boolean {

    const used: {[key: string]: boolean} = {};
    let pv = '';
    for (let key of keys) {
        if (!used[key]) {
            used[key] = true;
        } else {
            if (pv !== key) {
                return false;
            }
        }
        pv = key;
    }

    return true;
}