import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";
import {BishopBlack} from "../figures/bishop-black";
import {PawnWhite} from "../figures/pawn-white";
import {KingWhite} from "../figures/king-white";

export class Test08112 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test 08.11 - 02';
    }

    init() {
        this.chessMap.moves = [
            new QueenBlack(),
        ];

        this.chessMap.cells[3][1].figure = new QueenBlack();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[6][4], 'p', false)) {
            return true;
        }

        return false;

    }
}