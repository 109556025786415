import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";
import {BishopBlack} from "../figures/bishop-black";
import {PawnWhite} from "../figures/pawn-white";

export class CustomTest1 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Custom test 1';
    }

    init() {
        this.chessMap.moves = [
          new BishopBlack(),
        ];

        this.chessMap.cells[2][7].figure = new BishopBlack();
        this.chessMap.cells[1][8].figure = new PawnWhite();
        this.chessMap.cells[3][6].figure = new BishopWhite();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[1][8], 'b')) {
            return true;
        }

        return false;

    }
}