import {ChessMap} from "../../models/chess.map";


export type TPosition = {x: number, y: number} | null;

export const findFigure = (map: ChessMap, type: string, black: boolean = map.currentMoveBlack): TPosition => {

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {

            if (cell.figure && cell.figure.type === type && cell.figure.isBlack === black) {
                return {
                    x: rowId,
                    y: cellId
                };
            }

            cellId++;
        }

        rowId++;
    }

    return null;
}