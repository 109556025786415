import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";

export class TestMove6 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test Move 6';
    }

    init() {
        this.chessMap.moves = [
          new QueenBlack(),
          new QueenBlack(),
          new PawnDark(),
        ];
        this.chessMap.cells[3][3].figure = new BishopWhite();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[3][3], 'q') && this.isFigure(this.chessMap.cells[1][4], 'p') ) {
            return true;
        }

        return false;

    }
}