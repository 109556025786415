import {ChessMap} from "../models/chess.map";
import {Movement} from "../models/movement";


export class Chess {


    getMoves3(map: ChessMap): Movement[] {
        const moves = this.getAllMoves(map);

        const result: {[key: number]: Movement[]} = {
            3: [],
            2: [],
            1: []
        };

        for (const move of moves) {
            move.move(map);

            if (move.moveKilledKing()) {
                result[3].push(move);
            } else if (this.canMoves2(map)) {
                result[3].push(move);
            } else if (this.canMove1(map)) {
                result[2].push(move);
            } else {
                result[1].push(move);
            }

            move.unmove(map);
        }

        if (result[3].length) return result[3];
        if (result[2].length) return result[2];
        if (result[1].length) return result[1];

        return [];
    }

    getMoves2(map: ChessMap, returnFirst: boolean = false): Movement[] {
        const moves = this.getAllMoves(map);

        const result: {[key: number]: Movement[]} = {
            2: [],
            1: []
        };

        for (const move of moves) {
            move.move(map);

            if (move.moveKilledKing()) {
                result[2].push(move);
                if (returnFirst) { move.unmove(map); return [move]; }
            } else if (this.canMove1(map)) {
                result[2].push(move);
                if (returnFirst) { move.unmove(map); return [move]; }
            } else {
                result[1].push(move);
            }

            move.unmove(map);
        }

        if (result[2].length) return result[2];
        if (result[1].length && !returnFirst) return result[1];

        return [];
    }

    getMoves1(map: ChessMap, returnFirst: boolean = false): Movement[] {
        return this.getAllMoves(map, returnFirst);
    }

    canMoves2(map: ChessMap): boolean {
        return !!this.getMoves2(map, true).length;
    }

    canMove1(map: ChessMap): boolean {
        return !!this.getMoves1(map, true).length;
    }

    getFullMoves(map: ChessMap, black = map.currentMoveBlack): Movement[] {
        const moves = [];

        let rowId = 0;
        for (const row of map.cells) {
            let columnId = 0;


            for (const cell of row) {
                if (cell.figure && cell.figure.isBlack === black) {
                    const curMoves = cell.figure.getMoves(map, rowId, columnId);

                    for (const move of curMoves) {
                        moves.push(move);
                    }
                }

                columnId++;
            }

            rowId++;
        }

        return moves;
    }
    getFigureMovesCount(map: ChessMap, black = map.currentMoveBlack): number {
        let count = 0;

        let rowId = 0;
        for (const row of map.cells) {
            let columnId = 0;

            for (const cell of row) {
                if (cell.figure &&
                    cell.figure.isBlack === black &&
                    cell.figure.type !== 'p') {
                    const curMoves = cell.figure.getMoves(map, rowId, columnId);

                    count += curMoves.length ? 1 : 0;
                }

                columnId++;
            }

            rowId++;
        }

        return count;
    }

    getAllMoves(map: ChessMap, returnFirst: boolean = false): Movement[] {

        const figures = map.moves;

        const moves = [];

        for (const figure of figures) {
            let rowId = 0;
            for (const row of map.cells) {
                let columnId = 0;

                for (const cell of row) {
                    if (cell.figure && cell.figure.isBlack === map.currentMoveBlack && figure.type === cell.figure.type) {
                        const curMoves = cell.figure.getMoves(map, rowId, columnId);

                        for (const move of curMoves) {
                            moves.push(move);

                            if (returnFirst) return [move];
                        }
                    }

                    columnId++;
                }

                rowId++;
            }
        }

        return moves;
    }


    getMoves(map: ChessMap): Movement[] {

        const figures = map.moves;

        if (figures.length === 3) return this.getMoves3(map);
        if (figures.length === 2) return this.getMoves2(map);
        if (figures.length === 1) return this.getMoves1(map);



        return [];
    }


}

export const chessAlgo = new Chess();