import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";

export class TestAttackFigures5 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test attack priority 5';
    }

    init() {
        this.chessMap.moves = [
          new QueenBlack(),
        ];

        this.chessMap.cells[5][1].figure = new RookWhite();
        this.chessMap.cells[5][2].figure = new KnightWhite();
        this.chessMap.cells[4][2].figure = new QueenBlack();
        this.chessMap.cells[7][1].figure = undefined;
        this.chessMap.cells[7][8].figure = undefined;

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[5][1], 'q')) {
            return true;
        }

        return false;

    }
}