import {ChessMap} from "../../models/chess.map";
import {Movement} from "../../models/movement";
import {chessAlgo} from "../../algo/chess";
import {randomMove} from "../parts/random-move";
import {
    countOpen,
    enemyKingDied,
    getBeatSum,
    getEnKingAttackers,
    getKillCount, getKingAttackerBalance,
    getOurKingAttackers,
    getPawnDistance,
    isEndOfTheGame
} from "./math";
import {getBeatMap, TBeatMap} from "./beat-map";
import {getFigurePrice} from "./figure-price";
import {countTypeDistinct} from "../parts/count";
import {distanceKingHeightFromStart} from "../parts/distance";
import {TPosition} from "./find";

let results: Movement[] = [];
let mxResult = '';
let pvOking: {[key: number]: number} = {};
let pvOking2: {[key: number]: number} = {};
let pvEnBeat: {[key: number]: number} = {};

export let lastMx = '';
export const checkResult = (map: ChessMap, moves: Movement[],
                            types: string[], killed: number[], lastMove: string,
                            KillPos: TPosition[]) => {
    let nwPrice = getPrice(map, moves, types, killed, lastMove, KillPos);

    if (nwPrice > mxResult) {
        mxResult = nwPrice;
        lastMx = nwPrice;
        results = [moves[0]];
    } else if (nwPrice === mxResult) {
        results.push(moves[0]);
    }
}

export const prepareKilled = (killed: number) => {
    return (100000 + killed).toString().slice(-4);
}

export const getPawnResult = (map: ChessMap, moves: Movement[],
                              types: string[], killed: number[], lastMove: string,
                              KillPos: TPosition[], oKing1: number, oKing2: number, oBeat: number, enBeat: number) => {


    const pvOResult = pvOking[moves.length - 1];
    const pvO2Result = pvOking2[moves.length - 1];
    const pvEnBeatResult = pvEnBeat[moves.length - 1];

//     const killedCount = killed.reduce((acc, val) => acc+val+10, 0);
// console.log(killedCount);
//     if (killedCount >= 15) {
//         return '2_9998_93_' + prepareKilled(killedCount) + '_' + (99999 - oBeat) + '_';
//     }


    if (killed[0] >= 0 && pvOResult < oKing1) {
        return '2_9998_92_' + prepareKilled(killed[0]) + '_' + (99999 - oBeat) + '_';
    }

    if (killed[0] >= 0 && pvO2Result < oKing2) {
        return '2_9998_91_' + prepareKilled(killed[0]) + '_' + (99999 - oBeat) + '_';
    }

    if (killed[0] >= 0) {
        return '2_9998_90_' + prepareKilled(killed[0]) + '_' + (99999 - oBeat) + '_';
    }

    if (moves.length > 1 && moves[1].movement.columnFrom === moves[0].movement.columnTo
        && moves[1].movement.rowFrom === moves[0].movement.rowTo && killed[1] >= 0) {
        return '2_9998_8_' + oKing1 + '_'  + prepareKilled(killed[1]) + prepareKilled(killed[0]) + '_' + oKing2 + '_' + (99999 - oBeat) + '_';
    }

    if (pvOResult < oKing1 && types.indexOf('k') < 0) {
        return '2_9998_70_';
    }

    if (pvOResult === oKing1 && enBeat > pvEnBeatResult) {
        return '2_9998_71_';
    }

    const queenColumn = 5;

    if (moves[0].movement.columnFrom === queenColumn && Math.abs(moves[0].movement.rowFrom - moves[0].movement.rowTo) === 2) {
        const tk = types.includes('q') ? '6' : '46';

        return `2_9998_${tk}_`;
    }

    // open rook
    if ((moves[0].movement.columnFrom === 1 || moves[0].movement.columnFrom === 8) && Math.abs(moves[0].movement.rowFrom - moves[0].movement.rowTo) === 2) {
        const tk = types.includes('r') ? '5' : '45';

        return `2_9998_${tk}_`;
    }

    if ([1,2,7,8].includes(moves[0].movement.columnFrom)) {
        return '2_9998_44_';
    }

    if (moves[0].movement.transformTo) {
        return '2_9998_3_';
    }

    return null;
}

export const getFigureResult = (map: ChessMap, moves: Movement[],
                              types: string[], killed: number[], lastMove: string,
                              KillPos: TPosition[], oKing1: number, oKing2: number, beatMap: TBeatMap) => {

    const pvOResult = pvOking[moves.length - 1];
    const pvO2Result = pvOking2[moves.length - 1];



    const killedCount = killed.reduce((acc, val) => acc+val+10, 0);

    // if (killedCount >= 15) {
    //     return '2_9998_94_' + prepareKilled(killedCount) + '_' + oKing1 + '_';
    // }

    if (moves.length > 1 && moves[1].movement.columnFrom === moves[0].movement.columnTo
        && moves[1].movement.rowFrom === moves[0].movement.rowTo && killed[1] !== -1 && pvOking[moves.length - 2] < oKing1) {
        return '2_9997_92_' + prepareKilled(killed[1]) + prepareKilled(killed[0]);
    }

    if (pvOResult < oKing1 && types.indexOf('k') < 0 && killed[0] === -1) {
        return '2_9997_91_' + prepareKilled(killed[0]);
    }

    if (pvOResult < oKing1 && types.indexOf('k') < 0 && killed[0] !== -1) {
        return '2_9997_93_' + prepareKilled(killed[0]);
    }

    if (killed.length && killed[0] >= 0) {
        return '2_9997_82_' + prepareKilled(killed[0]);
    }


    if (moves.length > 1 && moves[1].movement.columnFrom === moves[0].movement.columnTo
        && moves[1].movement.rowFrom === moves[0].movement.rowTo && killed[1] >= 0) {
        return '2_9997_81_' + prepareKilled(killed[1]) + prepareKilled(killed[0]);
    }

    if (killed.length && killed[0] !== -1 && !beatMap[moves[0].movement.rowTo][moves[0].movement.columnTo].lvl1) {
        return '2_9997_7_' + prepareKilled(killed[0]);
    }

    if (moves.length > 1 && moves[1].movement.columnFrom === moves[0].movement.columnTo
        && moves[1].movement.rowFrom === moves[0].movement.rowTo && killed[1] !== -1 && !beatMap[moves[1].movement.rowTo][moves[1].movement.columnTo].lvl1) {
        return '2_9997_6_';
    }

    if (types.length && types[0] === 'h' && moves.length) {

        const direction = map.currentMoveBlack ? 0 : 7;
        const move = moves[0];

        if (Math.abs(move.movement.rowTo - direction) >= 2 && !beatMap[move.movement.rowTo][move.movement.columnTo].lvl1) {
            if (pvO2Result < oKing2) {
                return '2_9997_51_' + (999 - beatMap[move.movement.rowTo][move.movement.columnTo].lvl2) + '_';
            }

            return '2_9997_50_' + (999 - beatMap[move.movement.rowTo][move.movement.columnTo].lvl2) + '_';
        }
    }


    return null;
}

export const getKingResult = (map: ChessMap, moves: Movement[],
                              types: string[], killed: number[], lastMove: string,
                              KillPos: TPosition[], oKing1: number, beatMap: TBeatMap) => {

    const move = moves[0];
    if (killed.length && killed[0] !== -1 && types.length > 1 && types[1] === 'k') {
        return '2_9996_9_';
    }

    if (killed.length && killed[0] !== -1 && !beatMap[move.movement.rowTo][move.movement.columnTo].lvl1  && !beatMap[move.movement.rowTo][move.movement.columnTo].lvl2) {
        const direction = map.currentMoveBlack ? 0 : 7;

        return '2_9996_8_';
    }

    if (moves.length > 1 && types[0] === types[1]) {
        const move1 = moves[1];
        const direction = map.currentMoveBlack ? 0 : 7;
        return '2_9996_7_' + (999 - beatMap[move1.movement.rowTo][move1.movement.columnTo].lvl1) + '_'
            + (999 - beatMap[move1.movement.rowTo][move1.movement.columnTo].lvl2) + '_' + (99 - Math.abs(direction - move1.movement.rowTo)) + '_';
    }

    if (moves.length) {

        const direction = map.currentMoveBlack ? 0 : 7;
        return '2_9996_7_' + (999 - beatMap[move.movement.rowTo][move.movement.columnTo].lvl1) + '_'
            + (999 - beatMap[move.movement.rowTo][move.movement.columnTo].lvl2) + '_' + (99 - Math.abs(direction - move.movement.rowTo)) + '_';
    }

    return null;
}

export const getPrice = (map: ChessMap, moves: Movement[],
                         types: string[], killed: number[], lastMove: string,
                        KillPos: TPosition[]): string => {
    let result = '';

    const endGame = isEndOfTheGame(map);

    // 1
    const enDied = enemyKingDied(map);
    result += enDied ? '1_' : '0_';


    const beatMap = getBeatMap(map);

    const oKing1 = 999 - getOurKingAttackers(map, beatMap);
    const oKing2 = 999 - getOurKingAttackers(map, beatMap, 2);
    const enBeat1 = getBeatSum(map, beatMap, 1, !map.currentMoveBlack);
    const oBeat = getBeatSum(map, beatMap, 1, map.currentMoveBlack);


    pvOking[moves.length] = oKing1;
    pvOking2[moves.length] = oKing2;
    pvEnBeat[moves.length] = enBeat1;

    if (enDied) {
        result = '2_9999_' + (3-moves.length) + '_';
    } else if (types.length && types[0] === 'p') {
        const pawnStart = getPawnResult(map, moves, types, killed, lastMove, KillPos, oKing1, oKing2, oBeat, enBeat1);

        if (pawnStart) result = pawnStart;
    } else if (types.length && types[0] !== 'k') {
        const figureStart = getFigureResult(map, moves, types, killed, lastMove, KillPos, oKing1, oKing2, beatMap);

        if (figureStart) result = figureStart;
    } else if (types.length && types[0] === 'k') {
        const figureStart = getKingResult(map, moves, types, killed, lastMove, KillPos, oKing1, beatMap);

        if (figureStart) result = figureStart;
    }

    // 2

    result += prepareKilled(oKing1) + '_' + oBeat + '_' + enBeat1;

    return result;
}

export const getBestMove = (map: ChessMap, lastMove: string): Movement | null => {

    results = [];
    mxResult = getPrice(map, [], [], [], lastMove, []);

    // lvl 1
    const mv1 = chessAlgo.getMoves(map);

    for (const m1 of mv1) {
        const t1 = map.cells[m1.movement.rowFrom][m1.movement.columnFrom].figure?.type as string;
        const tt1 = map.cells[m1.movement.rowTo][m1.movement.columnTo].figure?.type;

        const a1 = tt1 ? (getFigurePrice(map, tt1, m1.movement.rowTo, m1.movement.columnTo) -
            getFigurePrice(map, t1, m1.movement.rowFrom, m1.movement.columnFrom)) * 2 : -1;

        const pos1 = { x: m1.movement.rowTo, y: m1.movement.columnTo };

        m1.move(map);


        checkResult(map, [m1], [t1], [a1], lastMove, [pos1]);

        const mv2 = chessAlgo.getMoves(map);

        for (const m2 of mv2) {
            const t2 = map.cells[m2.movement.rowFrom][m2.movement.columnFrom].figure?.type as string;
            const tt2 = map.cells[m2.movement.rowTo][m2.movement.columnTo].figure?.type;

            const a2 = tt2 ? (getFigurePrice(map, tt2, m2.movement.rowTo, m2.movement.columnTo) -
                getFigurePrice(map, t2, m2.movement.rowFrom, m2.movement.columnFrom)) * 2 : -1;

            const pos2 = { x: m2.movement.rowTo, y: m2.movement.columnTo };

            m2.move(map);

            checkResult(map, [m1, m2], [t1, t2], [a1, a2], lastMove, [pos1, pos2]);

            const mv3 = chessAlgo.getMoves(map);

            for (const m3 of mv3) {

                const t3 = map.cells[m3.movement.rowFrom][m3.movement.columnFrom].figure?.type as string;
                const tt3 = map.cells[m3.movement.rowTo][m3.movement.columnTo].figure?.type;

                const a3 = tt3 ? (getFigurePrice(map, tt3, m3.movement.rowTo, m3.movement.columnTo) -
                    getFigurePrice(map, t3, m3.movement.rowFrom, m3.movement.columnFrom)) * 2 : -1;

                const pos3 = { x: m3.movement.rowTo, y: m3.movement.columnTo };
                m3.move(map);

                checkResult(map, [m1, m2, m3], [t1, t2, t3], [a1, a2, a3], lastMove, [pos1, pos2, pos3]);

                m3.unmove(map);

            }

            m2.unmove(map);
        }

        m1.unmove(map);
    }


    const move = randomMove(results);




    return move;
};