import img from './images/wking.svg'
import {King} from "./king";


export class KingWhite extends King {
    moveVector = -1;


    constructor() {
        super(img, false);
    }


}