import React, {useEffect} from 'react';
import {ChessMapProvider, useChess} from "./ChessMapContext";
import {Cell} from "./models/cell";
import {Figure} from "./models/figure";
import {ChessMap} from "./models/chess.map";
import {Movement} from "./models/movement";
import {Chess, chessAlgo} from "./algo/chess";
import {whiteBot} from "./bot/white-bot";
import {TestKillKing} from "./tests/test-kill-king";
import {BaseTest} from "./tests/base-test";
import {TestKillQueen} from "./tests/test-kill-queen";
import {TestSaveKing} from "./tests/test-save-king";
import {TestSaveKingDoublet} from "./tests/test-save-king-doublet";
import {TestAttackFigures1} from "./tests/test-attack-figures1";
import {TestAttackFigures2} from "./tests/test-attack-figures2";
import {TestAttackFigures3} from "./tests/test-attack-figures3";
import {TestAttackFigures4} from "./tests/test-attack-figures4";
import {TestAttackFigures5} from "./tests/test-attack-figures5";
import {TestAttackFigures6} from "./tests/test-attack-figures6";
import {TestAttackFigures7} from "./tests/test-attack-figures7";
import {TestAttackFigures8} from "./tests/test-attack-figures8";
import {TestAttackFigures9} from "./tests/test-attack-figures9";
import {TestAttackFigures10} from "./tests/test-attack-figures10";
import {TestAttackFigures11} from "./tests/test-attack-figures11";
import {TestAttackFigures12} from "./tests/test-attack-figures12";
import {TestAttackFigures13} from "./tests/test-attack-figures13";
import {TestAttackFigures14} from "./tests/test-attack-figures14";
import {TestAttackFigures15} from "./tests/test-attack-figures15";
import {TestAttackFigures16} from "./tests/test-attack-figures16";
import {TestSaveKingMove1} from "./tests/test-save-king-move1";
import {TestSaveKingMove2} from "./tests/test-save-king-move2";
import {TestSaveKingMove3} from "./tests/test-save-king-move3";
import {TestSaveKingMove4} from "./tests/test-save-king-move4";
import {TestSaveKingMove5} from "./tests/test-save-king-move5";
import {TestSaveKingMove6} from "./tests/test-save-king-move6";
import {TestSaveKingMove7} from "./tests/test-save-king-move7";
import {TestSaveKingMove8} from "./tests/test-save-king-move8";
import {TestSaveKingMove9} from "./tests/test-save-king-move9";
import {TestSaveKingMove10} from "./tests/test-save-king-move10";
import {TestStart1} from "./tests/test-start1";
import {TestStart2} from "./tests/test-start2";
import {TestStart3} from "./tests/test-start3";
import {TestStart4} from "./tests/test-start4";
import {TestStart5} from "./tests/test-start5";
import {TestStart6} from "./tests/test-start6";
import {TestStart7} from "./tests/test-start7";
import {TestStart8} from "./tests/test-start8";
import {TestStart9} from "./tests/test-start9";
import {TestStart10} from "./tests/test-start10";
import {TestMove1} from "./tests/test-move1";
import {TestMove2} from "./tests/test-move2";
import {TestMove3} from "./tests/test-move3";
import {TestMove4} from "./tests/test-move4";
import {TestMove5} from "./tests/test-move5";
import {TestMove6} from "./tests/test-move6";
import {TestMove7} from "./tests/test-move7";
import {TestMove8} from "./tests/test-move8";
import {TestMove9} from "./tests/test-move9";
import {TestMove10} from "./tests/test-move10";
import {CustomTest1} from "./tests/custom-test-1";
import {Test06261} from "./tests/test0626-1";
import {Test06262} from "./tests/test0626-2";
import {Test06271} from "./tests/test0627-1";
import {Test06272} from "./tests/test0627-2";
import {Test07041} from "./tests/test0704-1";
import {Test07042} from "./tests/test0704-2";
import {blackBot} from "./bot/black-bot";
import {Test08111} from "./tests/test0811-1";
import {Test08112} from "./tests/test0811-2";
import {lastMx} from "./bot/true/get-best-move";
import {GreatBot, greatBot} from "./bot/great-bot";

export interface IPick {
    row: number;
    column: number;
}

const unpicked: IPick = {row: -1, column: -1};

const WAIT_NEXT_STEP_ON = 0;

const tests = [
    TestKillKing,
    TestKillQueen,
    TestSaveKing,
    TestSaveKingDoublet,
    TestAttackFigures1,
    TestAttackFigures2,
    TestAttackFigures3,
    TestAttackFigures4,
    TestAttackFigures5,
    TestAttackFigures6,
    TestAttackFigures7,
    TestAttackFigures8,
    TestAttackFigures9,
    TestAttackFigures10,
    TestAttackFigures11,
    TestAttackFigures12,
    TestAttackFigures13,
    TestAttackFigures14,
    TestAttackFigures15,
    TestAttackFigures16,
    TestSaveKingMove1,
    TestSaveKingMove2,
    TestSaveKingMove3,
    TestSaveKingMove4,
    TestSaveKingMove5,
    TestSaveKingMove6,
    TestSaveKingMove7,
    TestSaveKingMove8,
    TestSaveKingMove9,
    TestSaveKingMove10,
    TestStart1,
    TestStart2,
    TestStart3,
    TestStart4,
    TestStart5,
    TestStart6,
    TestStart7,
    TestStart8,
    TestStart9,
    TestStart10,
    TestMove1,
    TestMove2,
    TestMove3,
    TestMove4,
    TestMove5,
    TestMove6,
    TestMove7,
    TestMove8,
    TestMove9,
    TestMove10,
    CustomTest1,
    Test06261,
    Test06262,
    Test06271,
    Test06272,
    Test07041,
    Test07042,
    Test08111,
    Test08112,
];

export function ChessMapComponent() {

    let [moves, setMoves] = React.useState<Movement[]>([]);
    let [picked, setPicked] = React.useState<IPick>(unpicked);
    let [step, setStep] = React.useState<number>(0);
    let [x2Status, setX2Status] = React.useState<string>('Not calculated');
    let [runTestsIter, setRunTestsIter] = React.useState<number>(tests.length);
    let [waitForClick, setWaitForClick] = React.useState<number>(WAIT_NEXT_STEP_ON);
    let [winWhite, setWinWhite] = React.useState<number>(0);
    let [test, setCurrentTest] = React.useState<BaseTest|null>(null);
    let [winBlack, setWinBlack] = React.useState<number>(0);
    let [history, setHistory] = React.useState<Movement[]>([]);
    let [logicHistory, setLogicHistory] = React.useState<string[]>([]);
    let [results, setResults] = React.useState<{ [key: string]: boolean }>({});
    const provider = useChess();

    let prevStep = -1;

    const getDescLogic = (logicMove: string) => {
       if (logicMove[0] === '0') {
           return 'Best map move';
       }

       const parts = logicMove.split('_');

       if (logicMove[0] === '2' && parts[1] === '9999') {
           return '[ beat best move: ' + (3 - +parts[2]) + ' ]';
       }

       if (logicMove[0] === '2' && parts[1] === '9998') {

           const explainMap: {[key: string]: string} = {
               '90': 'Kill figure with high price',
               '91': 'Kill figure with high price (save king lvl2)',
               '92': 'Kill figure with high price (save king)',
               '93': 'Kill figure by sum all',
               '70': 'Save king',
               '71': 'Save king attack increase',
               '8': 'Attack too kill later',
               '6': 'Open Queen',
               '5': 'Open Rook',
               '46': 'Open Queen(later)',
               '45': 'Open Rook(later)',
               '44': 'Move to the field end',
               '3': 'Transform pawn'
           };


           return '[ pawn: ' + (explainMap[parts[2]] || '') + ' ]';


       }

       if (logicMove[0] === '2' && parts[1] === '9997') {

           const explainMap: {[key: string]: string} = {
               '91': 'Save king (kill attacker)',
               '92': 'Save king (two moves)',
               '93': 'Save king (defence)',
               '94': 'Killed by sum',
               '82': 'Kill figure with high price',
               '81': 'Kill figure with high price (two moves)',
               '7': 'Kill highest figure',
               '6': 'Kill with no danger',
               '50': 'Knight move (save)',
               '51': 'Knight move (save king)',
           };


           return '[ figure: ' + (explainMap[parts[2]] || '') + ' ]';


       }


       if (logicMove[0] === '2' && parts[1] === '9996') {

           const explainMap: {[key: string]: string} = {
               '9': 'Kill with no danger',
               '8': 'Kill no danger(last move)',
               '7': 'Go save',
           };


           return '[ king: ' + (explainMap[parts[2]] || '') + ' ]';


       }


       return 'Unknown';
    };

    useEffect(() => {
        const x2Map: {[key: string]: string} = {
            [-1]: 'White Leading',
            0: 'Draw',
            1: 'Black Leading',
        };

        setX2Status(x2Map[greatBot.getX2Status(provider.table.copy())]);

        if (test) {
            const move = test.nextMove();

            if (!move) {
                results[test.getStaticName()] = test.check();
                setResults({...results});

                if (runTestsIter < tests.length) {
                    setRunTestsIter(runTestsIter + 1);
                }
            } else {
                const speed = runTestsIter < tests.length ? 1  :1000;

                const tm = setTimeout(() => {
                    setPicked({row: move.movement.rowFrom, column: move.movement.columnFrom});

                    clearTimeout(tm);
                }, speed / 2);


                const tm2 = setTimeout(() => {
                    setPicked(unpicked);

                    move.move(provider.table);

                    history.push(move);
                    setHistory(history);

                    logicHistory.push(getDescLogic(lastMx));
                    setLogicHistory(logicHistory);

                    const mv = chessAlgo.getMoves(provider.table.copy());

                    setMoves(mv);

                    setStep(step + 1);

                    clearTimeout(tm2);
                }, speed);
            }

            return;
        }

        while (true) {
            const mv = chessAlgo.getMoves(provider.table.copy());

            if (mv.length) {
                setMoves(mv);
                break;
            }

            provider.table.generateNext3();
        }

        let winner = provider.table.isFinished();

        if (winner) {
            if (winner === 2) {
                setWinBlack(winBlack + 1)
            } else {
                setWinWhite(winWhite + 1);
            }

            startNewGame();

            return;
        }

        const type: number = 0;
        const stopStep: number = -1;

        if (type === 1 || step === prevStep ||
            type === 3 && step === stopStep) return;

        prevStep = step;

        
        const speed = type === 0 ? 1 : 1000;
        if (!provider.table.currentMoveBlack) {
            if (type === 0 || type === 3) {
                const move = blackBot.getMove(provider.table.copy());

                const tm = setTimeout(() => {

                    move.move(provider.table);

                    history.push(move);
                    setHistory(history);

                    setStep(step + 1);

                    clearTimeout(tm);
                }, speed);

            }
        } else {
            if (waitForClick) return;
            setWaitForClick(WAIT_NEXT_STEP_ON);

            const move = greatBot.getMove(provider.table.copy());

            if (type === 2) {

                const tm = setTimeout(() => {
                    setPicked({row: move.movement.rowFrom, column: move.movement.columnFrom});

                    clearTimeout(tm);
                }, speed / 2);
            }


            const tm = setTimeout(() => {
                if (type === 2) {
                    setPicked(unpicked);
                }

                move.move(provider.table);

                history.push(move);
                setHistory(history);

                logicHistory.push(getDescLogic(lastMx));
                setLogicHistory(logicHistory);

                setStep(step + 1);

                clearTimeout(tm);
            }, speed);
        }


    }, [step]);



    useEffect(() => {
        if (runTestsIter < tests.length) {
            const test = tests[runTestsIter];
            setTest(test);
        }
    }, [runTestsIter]);

    const isFromMove = (rowId: number, columnId: number) => {
        const id = moves.findIndex(move =>
            move.movement.rowFrom === rowId &&
            move.movement.columnFrom === columnId);

        return id >= 0;
    };
    const isToMove = (rowId: number, columnId: number) => {
        return !!findMoves(picked.row, picked.column, rowId, columnId).length;
    };

    const findMoves = (fromX: number, fromY: number, toX: number, toY: number) => {
        return moves.filter((move: Movement) =>
            move.movement.rowTo === toX &&
            move.movement.columnTo === toY &&
            move.movement.rowFrom === fromX &&
            move.movement.columnFrom === fromY);
    };

    const extraClass = (rowId: number, columnId: number) => {
        if (provider.table.isFinished()) {
            return '';
        }
        if (picked.row === -1) {
            return isFromMove(rowId, columnId) ? 'from-move' : '';
        } else {
            if (picked.row === rowId && picked.column === columnId) {
                return 'pick-move';
            }

            return isToMove(rowId, columnId) ? 'to-move' : '';
        }
    };

    const setTest = (test: typeof BaseTest) => {

        const nwTest = new test(new GreatBot());
        setCurrentTest(nwTest);
        nwTest.init();
        provider.setChessMap(nwTest.chessMap);
        waitForClick = 0;
        setWaitForClick(0);
        setHistory([]);
        setLogicHistory([]);

        const mv = chessAlgo.getMoves(nwTest.chessMap.copy());
        setMoves(mv);

        setStep(step ? 0 : 1);
    }

    const makeNextStep = () => {
        setWaitForClick(0);
        setStep(step + 1);
    };

    const startNewGame = () => {
        provider.setChessMap(new ChessMap());
        waitForClick = WAIT_NEXT_STEP_ON;
        setWaitForClick(WAIT_NEXT_STEP_ON);
        setCurrentTest(null);

        setHistory([]);
        setLogicHistory([]);

        setStep(0);
    }

    const click = (rowId: number, columnId: number) => {
        if (provider.table.isFinished()) {
            return;
        }

        if (picked.row === -1) {
            if (isFromMove(rowId, columnId)) {
                setPicked({row: rowId, column: columnId});
            } else {
                setPicked(unpicked)
            }
        } else {
            if (isToMove(rowId, columnId)) {
                const moves = findMoves(picked.row, picked.column, rowId, columnId);

                moves[0].move(provider.table);

                history.push(moves[0]);
                setHistory(history);

                setPicked(unpicked);
                setStep(step + 1);
            } else {
                setPicked(unpicked);
            }
        }
    };

    const runAllTests = () => {
        runTestsIter = 0;
        setRunTestsIter(0);
    };



    return <div>
        <div className="table">
            <div className="board">
                {provider.table.cells.map((row: Cell[], rowId: number) => {
                    return <div className="board-row">
                        {row.map((cell: Cell, columnId: number) => {
                            return <div onClick={() => {
                                click(rowId, columnId)
                            }}
                                        className={'board-cell ' + extraClass(rowId, columnId) + ' board-figure-' + cell.type}>{cell.figure ?
                                <img src={cell.figure.img} alt="figure"/> : <span>{cell.label}</span>}</div>;
                        })}
                    </div>
                })}
            </div>

            <div className="table-move">
                <div className="move">
                    Move: <span>{provider.table.currentMoveBlack ? 'black' : 'white'}</span>
                </div>
                <div className="table-figures">
                    {provider.table.moves.map((figure: Figure) => <img src={figure.img}/>)}
                </div>
                <div>
                    FInished: {provider.table.isFinished() ? 'finihsed' : "play"}
                </div>
                <div>
                    score: (white) {winWhite} - {winBlack} (black)
                </div>
                <div>
                    {provider.table.currentMoveBlack && waitForClick ? <button onClick={() => {
                        makeNextStep()
                    }}>Make next step</button> : <></>}
                </div>
                <div>x2 Status: {x2Status}</div>
                <div>History: {
                    history.map(item =>
                        String.fromCharCode(65 + item.movement.columnFrom - 1) +
                        (8-item.movement.rowFrom) + '>' +
                        String.fromCharCode(65 + item.movement.columnTo - 1) +
                        (8-item.movement.rowTo),
                    ).join(', ')
                }</div>
                <div>
                    Logic History {logicHistory.join(', ')}
                </div>
                <div>
                    <br/>
                    <button onClick={() => {startNewGame()}}>Start new game</button>
                </div>
            </div>
        </div>

        <div className="table2">
                <div className="tr">
                    <button onClick={() => {runAllTests()}}>Start all </button>
                    Passed: {Object.keys(results).reduce((acc: number, key: string) => acc + (results[key] ? 1 : 0), 0)} / {tests.length}
                </div>
            {tests.map(test => <div className="tr-inline">
                <div className="td">{test.getName()}</div>
                <div className="td">
                    {results.hasOwnProperty(test.getName()) ? results[test.getName()] ? 'passed' : 'not passed' : 'not tested'}
                </div>
                <div className="td">
                    <button onClick={() => {setTest(test)}}>Set this test</button>
                </div>
            </div>)}
            </div>
    </div>;
}