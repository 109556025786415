
import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";

export class TestMove10 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test Move 10';
    }

    init() {
        this.chessMap.moves = [
          new KnightBlack(),
          new KnightBlack(),
        ];

        this.chessMap.cells[2][1].figure = new KnightBlack();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[4][2], 'h') || this.isFigure(this.chessMap.cells[4][5], 'h')) {
            return true;
        }

        return false;

    }
}