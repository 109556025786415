import {Pawn} from "./pawn";

import img from './images/wpaw.svg'

export class PawnWhite extends Pawn {
    moveVector = -1;


    constructor() {
        super(img, false);
    }


}