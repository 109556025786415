import React from 'react';
import './App.scss';
import {ChessMapComponent} from "./ChessMap";
import {ChessMapProvider, useChess} from "./ChessMapContext";


function App() {


    return (
            <div className="App">
                <ChessMapProvider>
                    <ChessMapComponent/>
                </ChessMapProvider>
            </div>
    );
}

export default App;
