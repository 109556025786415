import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";

export class TestSaveKingDoublet extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Save king under doublet attack';
    }

    init() {
        this.chessMap.moves = [
          new PawnDark(),
          new KnightBlack(),
        ];
        this.chessMap.cells[1][4].figure = undefined;
        this.chessMap.cells[6][3].figure = undefined;
        this.chessMap.cells[7][4].figure = undefined;
        this.chessMap.cells[5][1].figure = new QueenWhite();
        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.chessMap.cells[2][3].figure && !this.chessMap.cells[1][5].figure) {
            return true;
        }

        return false;

    }
}