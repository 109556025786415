import {BaseTest, IBot} from "./base-test";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
export class TestMove1 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test Move 1';
    }

    init() {
        this.chessMap.moves = [
          new QueenWhite(),
          new PawnDark(),
          new PawnDark(),
        ];

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[2][6], 'q') && !this.isEmpty(this.chessMap.cells[1][4]) && !this.isEmpty(this.chessMap.cells[1][6])) {
            return true;
        }

        return false;

    }
}