
import img from './images/wknight.svg'
import {Knight} from "./knight";


export class KnightWhite extends Knight {


    constructor() {
        super(img, false);
    }


}