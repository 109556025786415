import {ChessMap} from "../models/chess.map";
import {Movement} from "../models/movement";
import {chessAlgo} from "../algo/chess";
import {countKingAttackers, countTypeDistinct} from "./parts/count";
import {getBestMove} from "./great/get-best-move";

export type POS = number[];

export class GreatBot {

    mem: Movement[] = [];

    lastMove: string = '';

    getMove(map: ChessMap): Movement {


        if (this.mem.length) {
            return this.mem.shift() as Movement;
        }

        const mv = chessAlgo.getMoves(map);


        let resultMove: Movement | null = null;

        if (resultMove = getBestMove(map, this.lastMove)) {
            const fig = map.cells[resultMove.movement.rowFrom][resultMove.movement.columnFrom].figure;
            if (fig) {
                this.lastMove = fig.type;
            }

            return resultMove;
        }

        return mv[0];
    }

    getX2Status(map: ChessMap): number {

        const figuresMe = countTypeDistinct(map, true);
        const figuresEn = countTypeDistinct(map, false);

        if (figuresEn > figuresMe + 1) return -1;
        if (figuresEn < figuresMe - 1) return 1;

        const attackersMe = countKingAttackers(map, true);
        const attackersEn = countKingAttackers(map, false);

        if (figuresEn > figuresMe + 2) return -1;
        if (figuresEn < figuresMe - 2) return 1;

        return 0;
    }

}

export const greatBot: GreatBot = new GreatBot();