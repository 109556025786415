import {ChessMap} from "../models/chess.map";
import {Movement} from "../models/movement";
import {chessAlgo} from "../algo/chess";
import {tryBeatEnemyKing} from "./parts/try-beat-enemy-king";
import {tryBeatOrMove} from "./parts/try-beat-or-move";
import {countKingAttackers, countType, countTypeDistinct} from "./parts/count";



export type POS = number[];

export interface IRoad {
    result: number;
    moves: Movement[];
}

export interface ISaveKingStats {
    one: number;
    two: number;
    ourPotential: number;
    enPotential: number;
    moves: number;
}

export class BlackBot {

    mem: Movement[] = [];

    getMove(map: ChessMap): Movement {


        if (this.mem.length) {
            return this.mem.shift() as Movement;
        }

        const mv = chessAlgo.getMoves(map);

        let resultMove: Movement | null = null;

        if (resultMove = tryBeatEnemyKing(map)) { return resultMove; }

        if (resultMove = tryBeatOrMove(map)) { return resultMove; }

        return mv[0];
    }

    getX2Status(map: ChessMap): number {

        const figuresMe = countTypeDistinct(map, true);
        const figuresEn = countTypeDistinct(map, false);

        if (figuresEn > figuresMe + 1) return -1;
        if (figuresEn < figuresMe - 1) return 1;

        const attackersMe = countKingAttackers(map, true);
        const attackersEn = countKingAttackers(map, false);

        if (figuresEn > figuresMe + 2) return -1;
        if (figuresEn < figuresMe - 2) return 1;

        return 0;
    }

}

export const blackBot: BlackBot = new BlackBot();