import {Cell, ECell} from "./cell";
import {RookWhite} from "../figures/rook-white";
import {KnightWhite} from "../figures/knight-white";
import {BishopWhite} from "../figures/bishop-white";
import {QueenWhite} from "../figures/queen-white";
import {KingWhite} from "../figures/king-white";
import {RookBlack} from "../figures/rook-black";
import {KnightBlack} from "../figures/knight-black";
import {BishopBlack} from "../figures/bishop-black";
import {QueenBlack} from "../figures/queen-black";
import {KingBlack} from "../figures/king-black";
import {Figure} from "./figure";
import {PawnWhite} from "../figures/pawn-white";
import {PawnDark} from "../figures/pawn-dark";


const getRowPawnWhite = (id: number) => {
    const arr = [];
    arr.push(new Cell(id.toString(), ECell.OUTBOARD));

    for (let i=0; i<8; i++) {
        const cell = new Cell('', (id+i+1)%2 ? ECell.DARK : ECell.LIOHT );

        cell.figure = new PawnWhite();

        arr.push(cell);
    }

    return arr;
};

const getRowPawnBlack= (id: number) => {
    const arr = [];
    arr.push(new Cell(id.toString(), ECell.OUTBOARD));

    for (let i=0; i<8; i++) {
        const cell = new Cell('', (id+i+1)%2 ? ECell.DARK : ECell.LIOHT );

        cell.figure = new PawnDark();

        arr.push(cell);
    }

    return arr;
};

const getWhiteRow = (id: number) => {
    const arr = [];
    arr.push(new Cell(id.toString(), ECell.OUTBOARD));

    const figures = [
        new RookWhite(),
        new KnightWhite(),
        new BishopWhite(),
        new QueenWhite(),
        new KingWhite(),
        new BishopWhite(),
        new KnightWhite(),
        new RookWhite(),
    ];

    for (let i=0; i<8; i++) {
        const cell = new Cell('', (id+i+1)%2 ? ECell.DARK : ECell.LIOHT );

        cell.figure = figures[i];

        arr.push(cell);
    }

    return arr;

};

const getBlackRow = (id: number) => {
    const arr = [];
    arr.push(new Cell(id.toString(), ECell.OUTBOARD));

    const figures = [
        new RookBlack(),
        new KnightBlack(),
        new BishopBlack(),
        new QueenBlack(),
        new KingBlack(),
        new BishopBlack(),
        new KnightBlack(),
        new RookBlack(),
    ];

    for (let i=0; i<8; i++) {
        const cell = new Cell('', (id+i+1)%2 ? ECell.DARK : ECell.LIOHT );

        cell.figure = figures[i];

        arr.push(cell);
    }

    return arr;

};


const getRow = (id: number) => {
    const arr = [];

    arr.push(new Cell(id.toString(), ECell.OUTBOARD));

    for (let i=0; i<8; i++) {
        arr.push(new Cell('.', (id+i+1)%2 ? ECell.DARK : ECell.LIOHT ));
    }

    return arr;
};

const getAlphabet = () => {
    const arr = [];

    arr.push(new Cell('', ECell.OUTBOARD));

    for (let i=0; i<8; i++) {
        arr.push(new Cell(String.fromCharCode('A'.charCodeAt(0) + i), ECell.OUTBOARD));
    }

    return arr;
};

const figures = [
    new RookBlack(),
    new KnightBlack(),
    new BishopBlack(),
    new QueenBlack(),
    new KingBlack(),
    new PawnDark(),
];

export class ChessMap {
    moveStep = 10;

    cells: Cell[][] = [
        getBlackRow(8),
        getRowPawnBlack(7),
        getRow(6),
        getRow(5),
        getRow(4),
        getRow(3),
        getRowPawnWhite(2),
        getWhiteRow(1),
        getAlphabet(),
    ];

    currentMoveBlack: boolean = true;

    constructor() {

        // this.currentMoveBlack = false;
        // this.cells[1][5].figure = undefined;
        // this.cells[1][4].figure = undefined;
        // this.cells[5][4].figure = new KnightWhite();
    }


    moves: Figure[] = [
    ];

    generateNext3() {
        this.currentMoveBlack = !this.currentMoveBlack;

        this.moveStep++;

        this.moves = [];

        for (let i=0; i<3; i++) {
            this.moves.push(figures[Math.floor(Math.random() * figures.length)]);
        }

        // this.moves = [figures[1], figures[1], figures[4]];
    }

    isFinished() {
        let count = 0;
        

        for (const row of this.cells) {
            for (const cell of row) {
                if (cell.figure && cell.figure.type === 'k') {
                    count |= cell.figure.isBlack ? 1 : 2;
                }
            }
        }

        return count ^ 3;
    }

    copy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }
}