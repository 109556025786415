import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";

export class TestStart1 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Save game start 1';
    }

    init() {
        this.chessMap.moves = [
          new PawnDark(),
          new PawnDark(),
          new PawnDark(),
        ];

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (chessAlgo.getFullMoves(this.chessMap).length >= 30 && this.isFigure(this.chessMap.cells[1][4], 'p') && this.isFigure(this.chessMap.cells[1][6], 'p')) {
            return true;
        }

        return false;

    }
}