import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";
import {BishopBlack} from "../figures/bishop-black";
import {PawnWhite} from "../figures/pawn-white";

export class Test06261 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test 26.06 - 01';
    }

    init() {
        this.chessMap.moves = [
            new PawnDark(),
            new BishopBlack(),
            new KnightBlack(),
        ];

        this.chessMap.cells[0][2].figure = undefined;
        this.chessMap.cells[0][4].figure = undefined;
        this.chessMap.cells[1][5].figure = undefined;
        this.chessMap.cells[2][3].figure = new KnightBlack();
        this.chessMap.cells[2][5].figure = new PawnDark();
        this.chessMap.cells[2][6].figure = new QueenBlack();
        this.chessMap.cells[3][2].figure = new KnightWhite();
        this.chessMap.cells[4][3].figure = new BishopWhite();
        this.chessMap.cells[4][5].figure = new PawnWhite();
        this.chessMap.cells[7][1].figure = undefined;
        this.chessMap.cells[7][6].figure = undefined;
        this.chessMap.cells[6][5].figure = undefined;
        this.chessMap.cells[7][2].figure = new RookWhite();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if ((this.isFigure(this.chessMap.cells[2][1], 'p') || this.isFigure(this.chessMap.cells[2][3], 'p')) &&
            (this.isFigure(this.chessMap.cells[3][1], 'h') || this.isFigure(this.chessMap.cells[3][5], 'h'))) {
            return true;
        }

        return false;

    }
}