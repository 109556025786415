import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";
import {BishopBlack} from "../figures/bishop-black";
import {PawnWhite} from "../figures/pawn-white";
import {KingWhite} from "../figures/king-white";

export class Test06271 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test 27.06 - 01';
    }

    init() {
        this.chessMap.moves = [
            new RookBlack(),
            new RookBlack(),
            new KnightBlack(),
        ];

        this.chessMap.cells[0][7].figure = undefined;
        this.chessMap.cells[1][1].figure = undefined;
        this.chessMap.cells[1][5].figure = undefined;
        this.chessMap.cells[3][1].figure = new PawnDark();
        this.chessMap.cells[2][5].figure = new PawnDark();
        this.chessMap.cells[3][3].figure = new PawnWhite();
        this.chessMap.cells[6][4].figure = new KingWhite();
        this.chessMap.cells[7][5].figure = undefined;

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[2][3], 'r') && this.isFigure(this.chessMap.cells[2][1], 'h')) {
            return true;
        }

        return false;

    }
}