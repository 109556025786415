import {ChessMap} from "../../models/chess.map";
import {Movement} from "../../models/movement";
import {chessAlgo} from "../../algo/chess";
import {randomMove} from "./random-move";

export const tryBeatEnemyKing = (map: ChessMap, step = 1): Movement | null => {
    // can kill step 1
    const mv = chessAlgo.getMoves(map);

    const results: Movement[] = [];

    for (const move of mv) {
        move.move(map);

        if (move.moveKilledKing()) {
            results.push(move);

            move.unmove(map);
        } else {

            const nxt = map.moves.length ? tryBeatEnemyKing(map, step + 1) : null;

            move.unmove(map);

            if (nxt) {
                results.push(move);
            }
        }
    }


    return randomMove(results);
}
