import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";

export class TestSaveKingMove4 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Save king move 4';
    }

    init() {
        this.chessMap.moves = [
          new KingBlack(),
        ];

        this.chessMap.cells[2][4].figure = new KnightWhite();
        this.chessMap.cells[1][5].figure = undefined;
        this.chessMap.cells[1][4].figure = undefined;

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[1][4], 'k')) {
            return true;
        }

        return false;

    }
}