import {Pawn} from "./pawn";

import img from './images/bpaw.svg'

export class PawnDark extends Pawn {
    moveVector = 1;


    constructor() {
        super(img, true);
    }


}