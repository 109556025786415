
import img from './images/bking.svg'
import {King} from "./king";


export class KingBlack extends King {
    moveVector = 1;


    constructor() {
        super(img, true);
    }


}