import {ChessMap} from "../../models/chess.map";
import {figurePrice} from "../parts/price";
import {isAttackingKing} from "../parts/attack";

export const getFigurePrice = (map: ChessMap, type: string, rowId: number, columnId: number, isBeat = false) => {
    if (!map.cells[rowId][columnId].figure) return 0;

    const isBlack = map.cells[rowId][columnId].figure?.isBlack;
    let lastRow = isBlack === true ? 6 : 1;

    if (isAttackingKing(map, rowId, columnId)) {
        return 11;
    }

    if (type === 'k' && isBlack !== map.currentMoveBlack && isBeat) {
        return 12;
    }

    if (rowId === lastRow && type === 'p') {
        return figurePrice['q'] - 1;
    }

    return figurePrice[type];
};