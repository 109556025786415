import {Bishop} from "./bishop";
import img from './images/bbishop.svg'


export class BishopBlack extends Bishop {


    constructor() {
        super(img, true);
    }


}