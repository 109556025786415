
import img from './images/bqueen.svg'
import {Queen} from "./queen";


export class QueenBlack extends Queen {


    constructor() {
        super(img, true);
    }


}