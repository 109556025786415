import {ChessMap} from "../models/chess.map";
import {Movement} from "../models/movement";
import {Cell} from "../models/cell";

export interface IBot {
    getMove: (map: ChessMap) => Movement | null;
}

export class BaseTest {
    constructor(protected bot: IBot) {
    }


    public chessMap = new ChessMap();

    static getName(): string {
        return 'not set';
    };

    getStaticName() {
        return (this.constructor as typeof BaseTest).getName();
    }

    init() {

    }

    check() {
        return false;
    }

    nextMove(): Movement | null {
        return this.bot.getMove(this.chessMap);
    }

    isFigure(cell: Cell, type: string, isBlack: boolean = this.chessMap.currentMoveBlack) {
        return cell.figure && cell.figure.type === type && cell.figure.isBlack === isBlack;
    }

    isEmpty(cell: Cell) {
        return !cell.figure;
    }
}