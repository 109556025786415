import {ChessMap} from "../../models/chess.map";


export const isAttackingKing = (map: ChessMap, row: number, column: number): boolean => {

    const figure = map.cells[row][column].figure;

    if (!figure) return false;

    const moves = figure.getMoves(map, row, column);

    for (const move of moves) {

        move.move(map, true);

        const killed = move.moveKilledKing();

        move.unmove(map);

        if (killed) return true;
    }

    return false;
};