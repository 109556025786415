import {ChessMap} from "../../models/chess.map";
import {findFigure} from "./find";
import {TBeatMap} from "./beat-map";
import {figurePrice} from "../parts/price";
import {getFigurePrice} from "./figure-price";
import {countTypeDistinct} from "../parts/count";
import {Movement} from "../../models/movement";


export const enemyKingDied = (map:ChessMap) => {
    return !findFigure(map, 'k', !map.currentMoveBlack);
}

export const getOurKingAttackers = (map: ChessMap, beatMap: TBeatMap, lvl = 1) => {

    const pos = findFigure(map, 'k');

    if (pos) {
        return beatMap[pos.x][pos.y][lvl === 1 ? 'lvl1' : 'lvl2'];
    }

    return 0;
};
export const getEnKingAttackers = (map: ChessMap, beatMap: TBeatMap, lvl = 1) => {

    const pos = findFigure(map, 'k', !map.currentMoveBlack);

    if (pos) {
        return beatMap[pos.x][pos.y][lvl === 1 ? 'lvl1' : 'lvl2'];
    }

    return 0;
};

export const getBeatSum = (map: ChessMap, beatMap: TBeatMap, lvl = 1, black = map.currentMoveBlack) => {

    let beatCount = 0;
    let beatSum = 0;

    const lvlKey = lvl === 1 ? 'lvl1' : 'lvl2';

    let rowId = 0;
    for (const row of map.cells) {

        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.type !== 'p') {
                const beat = beatMap[rowId][cellId][lvlKey];
                if (beat) {
                    beatCount++;
                    beatSum += beat * getFigurePrice(map, cell.figure.type, rowId, cellId);
                }
            }
            cellId++;
        }
        rowId++;
    }

    return beatCount * 1000000 + beatSum;
}


export const getKillCount = (killed: number[], lvl = 1) => {
    let sum = 0;
    const mn = lvl === 1 ? 1 : -1;

    for (let kill of killed) {
        if (mn * kill >= 0 && kill !== -1) {
            sum += mn * kill + 1;
        }
    }

    return lvl === 1 ? sum : 1000 - sum;
}

export const isEndOfTheGame = (map: ChessMap) => {
    return countTypeDistinct(map, map.currentMoveBlack) + countTypeDistinct(map, !map.currentMoveBlack) < 7;
};

export const getPawnDistance = (map: ChessMap, black = map.currentMoveBlack) => {
    let distance = 0;

    let rowId = 0;
    for (const row of map.cells) {

        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.type === 'p') {

                let lastRow = (map.cells[rowId][cellId].figure?.isBlack ? 1 : -1) === -1 ? 6 : 1;


                distance += Math.abs(lastRow - rowId);
            }

            cellId++;
        }

        rowId++;
    }

    return distance;
};

export const countOpen = (map: ChessMap,  type: string, black = map.currentMoveBlack) => {

    let distance = 0;

    const kingPos = findFigure(map, 'k');

    let rowId = 0;
    for (const row of map.cells) {

        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.type === type) {
                let moves = cell.figure.getMoves(map, rowId, cellId);

                if (type === 'r' && kingPos && rowId === kingPos.x) {
                    moves = moves.filter((move: Movement) => {
                       return Math.abs(move.movement.rowFrom - move.movement.rowTo)  +
                           Math.abs(move.movement.columnFrom - move.movement.columnTo) > 1;
                    });
                }
                if (type === 'r' && kingPos && Math.abs(rowId - kingPos.x) > 1) {
                    distance += 2000;
                } else if (moves.length) {
                    distance += 1000 + moves.length;
                }
            }

            cellId++;
        }

        rowId++;
    }

    return distance;

};

export const getKingAttackerBalance = (map: ChessMap, beatMap: TBeatMap,) => {

    let distance = 0;

    const kingPos = findFigure(map, 'k');

    let rowId = 0;
    for (const row of map.cells) {

        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === !map.currentMoveBlack) {
                const mvs = cell.figure.getMoves(map, rowId, cellId);

                for (const mv of mvs) {
                    if (kingPos && mv.movement.rowTo === kingPos.x && mv.movement.columnTo === kingPos.y) {
                        distance += beatMap[rowId][cellId].lvl1;
                    }
                }
            }

            cellId++;
        }

        rowId++;
    }

    return distance;
}