import {ChessMap} from "../../models/chess.map";
import {KingBlack} from "../../figures/king-black";


export const distanceKingHeightFromStart = (map: ChessMap, black: boolean) => {
    let rowId = 0;
    for (const row of map.cells) {

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.type === 'k') {
                const vector = (cell.figure as KingBlack).moveVector;

                const rowStart = vector === 1 ? 0 : 7;

                return Math.abs(rowStart - rowId);
            }
        }

        rowId++;
    }

    return 0;
};