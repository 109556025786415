
import img from './images/wrook.svg'
import {Rook} from "./rook";


export class RookWhite extends Rook {

    constructor() {
        super(img, false);
    }


}