import {Figure} from "../models/figure";
import {ChessMap} from "../models/chess.map";
import {Movement} from "../models/movement";


export class Knight extends Figure {


    constructor(public img: string, public isBlack: boolean) {
        super(img, isBlack, 'h');
    }


    getMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
        const movements = [];

        for (let stepX=-2; stepX<=2; stepX++) {
            const stepY = 3 - Math.abs(stepX);

            if (stepX !== 0 && this.canMove(map, fromX + stepX, fromY + stepY, this.isBlack)) {
                movements.push(new Movement({
                    rowFrom: fromX,
                    columnFrom: fromY,
                    rowTo: fromX + stepX,
                    columnTo: fromY + stepY,
                }))
            }

            if (stepX !== 0 && this.canMove(map, fromX + stepX, fromY - stepY, this.isBlack)) {
                movements.push(new Movement({
                    rowFrom: fromX,
                    columnFrom: fromY,
                    rowTo: fromX + stepX,
                    columnTo: fromY - stepY,
                }))
            }
        }

        return movements;
    }

    getSafeMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
        const movements = [];

        for (let stepX=-2; stepX<=2; stepX++) {
            const stepY = 3 - Math.abs(stepX);

            if (stepX !== 0 && this.canMove(map, fromX + stepX, fromY + stepY, !this.isBlack)) {
                const toX = fromX + stepX;
                const toY = fromY + stepY;

                if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack === this.isBlack) {
                    movements.push(new Movement({
                        rowFrom: fromX,
                        columnFrom: fromY,
                        rowTo: fromX + stepX,
                        columnTo: fromY + stepY,
                    }));
                }
            }

            if (stepX !== 0 && this.canMove(map, fromX + stepX, fromY - stepY, !this.isBlack)) {
                const toX = fromX + stepX;
                const toY = fromY - stepY;

                if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack === this.isBlack) {
                    movements.push(new Movement({
                        rowFrom: fromX,
                        columnFrom: fromY,
                        rowTo: fromX + stepX,
                        columnTo: fromY - stepY,
                    }));
                }
            }
        }

        return movements;
    }

    getAttackMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {
        const movements = [];

        for (let stepX=-2; stepX<=2; stepX++) {
            const stepY = 3 - Math.abs(stepX);

            if (stepX !== 0 && this.canMove(map, fromX + stepX, fromY + stepY, this.isBlack)) {
                const toX = fromX + stepX;
                const toY = fromY + stepY;

                if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack !== this.isBlack) {
                    movements.push(new Movement({
                        rowFrom: fromX,
                        columnFrom: fromY,
                        rowTo: fromX + stepX,
                        columnTo: fromY + stepY,
                    }));
                }
            }

            if (stepX !== 0 && this.canMove(map, fromX + stepX, fromY - stepY, this.isBlack)) {
                const toX = fromX + stepX;
                const toY = fromY - stepY;

                if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack !== this.isBlack) {
                    movements.push(new Movement({
                        rowFrom: fromX,
                        columnFrom: fromY,
                        rowTo: fromX + stepX,
                        columnTo: fromY - stepY,
                    }));
                }
            }
        }

        return movements;
    }
}