import * as React from "react";
import {ChessMap} from "./models/chess.map";

interface ChessContextType {
    table: ChessMap;
    setChessMap: (table: ChessMap) => void;
}

let ChessMapContext = React.createContext<ChessContextType>(null!);

export function ChessMapProvider({ children }: { children: React.ReactNode }) {
    let [chessMap, setChessMap] = React.useState<ChessMap>(new ChessMap());

    let value = { table: chessMap, setChessMap };

    return <ChessMapContext.Provider value={value}>{children}</ChessMapContext.Provider>;
}

export function useChess() {
    return React.useContext(ChessMapContext);
}