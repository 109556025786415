import {ChessMap} from "../../models/chess.map";
import {E_MOVE_PRICE, figurePrice} from "./price";
import {chessAlgo} from "../../algo/chess";
import {King} from "../../figures/king";
import {isAttackingKing} from "./attack";

export const countPotential = (map: ChessMap, black: boolean) => {

    let potential = 0;

    const used: {[key: string]: boolean} = {};

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black) {
                const mv = cell.figure.getMoves(map, rowId, cellId);

                for (const move of mv) {
                    const to = map.cells[move.movement.rowTo][move.movement.columnTo];

                    if (to.figure && to.figure.isBlack !== black) {
                        const type = map.cells[move.movement.rowFrom][move.movement.columnFrom].figure?.type as string;

                        const key = `${type}_${move.movement.rowTo}_${move.movement.columnTo}`;

                        if (!used[key]) {
                            potential += figurePrice[to.figure.type];
                            used[key] = true;
                        } else {
                            potential += figurePrice[to.figure.type] * E_MOVE_PRICE.SECOND_BEAT_K;
                        }
                    }
                }
            }

            cellId++;
        }

        rowId++;
    }

    return potential;
}

export const countKingAttackersStream = (map: ChessMap, black = map.currentMoveBlack) => {
    let result = 0;
    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            const figure = cell.figure;

            if (figure && figure.isBlack === black) {
                const moves =  figure.getMoves(map, rowId, cellId);

                for (const mv of moves) {
                    if (isAttackingKing(map, mv.movement.rowTo, mv.movement.columnTo)) {
                        result++;
                    }
                }
            }

            cellId++;
        }

        rowId++;
    }

    return result;
}
export const countKingAttackers = (map: ChessMap, black = map.currentMoveBlack) => {
    let result = 0;
    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            const figure = cell.figure;

            if (figure && figure.isBlack === black && isAttackingKing(map,rowId, cellId)) {
                return result++;
            }

            cellId++;
        }

        rowId++;
    }

    return result;
}

export const countPotentialLvl2 = (map: ChessMap, black: boolean, queenMn: number = 1, beatK: number = E_MOVE_PRICE.SECOND_BEAT_K) => {

    let potential = 0;

    const used: {[key: string]: boolean} = {};

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black) {
                const mv2 = cell.figure.getMoves(map, rowId, cellId);

                for (const move2 of mv2) {
                    move2.move(map, true);

                    const mv = chessAlgo.getFullMoves(map, black);

                    for (const move of mv) {
                        const to = map.cells[move.movement.rowTo][move.movement.columnTo];

                        if (to.figure && to.figure.isBlack !== black) {
                            const type = map.cells[move.movement.rowFrom][move.movement.columnFrom].figure?.type as string;

                            const key = `${type}_${move.movement.rowTo}_${move.movement.columnTo}`;

                            if (!used[key]) {
                                potential += (to.figure.type === 'q' ? queenMn : 1) * figurePrice[to.figure.type];
                                used[key] = true;
                            } else {
                                potential += figurePrice[to.figure.type] * beatK;
                            }
                        }
                    }


                    move2.unmove(map);
                }
            }

            cellId++;
        }

        rowId++;
    }

    return potential;
}


export const countCanMove = (map: ChessMap, black: boolean) => {
    let rowId = 0;
    let can = 0;

    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.getMoves(map, rowId, cellId).length) {
                can++;
            }

            cellId++;
        }

        rowId++;
    }

    return can;
}

export const countCanMoveDistinct = (map: ChessMap, black: boolean) => {
    let rowId = 0;
    let can = 0;
    let used: {[key: string]: boolean} = {};

    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.type !== 'p' && cell.figure.isBlack === black && cell.figure.getMoves(map, rowId, cellId).length) {
                if (!used[cell.figure.type]) {
                    used[cell.figure.type] = true;
                    can++;
                }
            }

            cellId++;
        }

        rowId++;
    }

    // console.log(map, can);

    return can;
}

export const countType = (map: ChessMap, figure: string, black: boolean) => {

    let potential = 0;

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.type === figure) {
                potential++;
            }

            cellId++;
        }

        rowId++;
    }

    return potential;
}
export const countTypeDistinct = (map: ChessMap, black: boolean) => {


    let used: {[key: string]: boolean} = {};

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black) {
                used[cell.figure.type]=true;
            }

            cellId++;
        }

        rowId++;
    }

    return Object.keys(used).length;
}


export const countRookOpen = (map: ChessMap, black: boolean) => {

    let potential = 0;

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && (cell.figure.type === 'r' || cell.figure.type === 'q')) {
                const moves = cell.figure.getMoves(map, rowId, cellId);

                for (const move of moves) {
                    if (Math.abs(move.movement.rowTo - move.movement.rowFrom) > 1 || Math.abs(move.movement.columnTo - move.movement.columnFrom) > 1) {
                        potential += cell.figure.type === 'q' ? 2 : 1;
                        break;
                    }
                }
            }

            cellId++;
        }

        rowId++;
    }

    return potential;
}

export const countKingDefenders = (map: ChessMap, black: boolean) => {

    let potential = 0;

    let rowId = 0;
    for (const row of map.cells) {
        let cellId = 0;

        for (const cell of row) {
            if (cell.figure && cell.figure.isBlack === black && cell.figure.type === 'k') {
                const figure = cell.figure as King;
                const deltaRow = figure.moveVector;

                if (map.cells[rowId+deltaRow] && map.cells[rowId+deltaRow][cellId+1] && map.cells[rowId+deltaRow][cellId+1].figure) {
                    potential += map.cells[rowId+deltaRow][cellId+1].figure?.type === 'p' ? 1 : 0.5;
                }

                if (map.cells[rowId+deltaRow] && map.cells[rowId+deltaRow][cellId-1] && map.cells[rowId+deltaRow][cellId-1].figure) {
                    potential += map.cells[rowId+deltaRow][cellId-1].figure?.type === 'p' ? 1 : 0.5;
                }
            }

            cellId++;
        }

        rowId++;
    }

    return potential;
}