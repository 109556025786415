import {ChessMap} from "../../models/chess.map";


export type TBeatMap = { lvl1: number, lvl2: number, lvl3: number, figMap: {[key: string]: boolean} }[][];

export const skipMove = (map: TBeatMap, x: number, y: number, mxlvl: number, fig: string) => {
    for (let lvl = 1; lvl <= mxlvl; lvl++) {
        if (map[x][y].figMap[fig + '_' + lvl]) return true;
    }

    map[x][y].figMap[fig + '_' + mxlvl] = true;

    return false;
};


export const getBeatMap = (map: ChessMap): TBeatMap => {

    const result: TBeatMap = [];

    for (const row of map.cells) {
        result.push([]);

        for (const cell of row) {
            result[result.length - 1].push({lvl1: 0, lvl2: 0, lvl3: 0, figMap: {}});
        }
    }

    let rowId = 0;


    for (const row of map.cells) {

        let cellId = 0;

        for (const cell of row) {
            if (cell.figure) {

                const mvs1 = cell.figure.getMoves(map, rowId, cellId);

                for (const mv1 of mvs1) {
                    const x1 = mv1.movement.rowTo;
                    const y1 = mv1.movement.columnTo;

                    if (skipMove(result, x1, y1, 1, cell.figure.type)) {
                        continue;
                    }

                    result[x1][y1].lvl1++;


                    mv1.move(map, true);

                    const fig = map.cells[x1][y1].figure;
                    if (!fig) {
                        console.log('bug no figure on step 2');
                        continue;
                    }


                    const mvs2 = fig.getMoves(map, x1, y1);

                    for (const mv2 of mvs2) {
                        const x2 = mv2.movement.rowTo;
                        const y2 = mv2.movement.columnTo;

                        if (x2 !== rowId || y2 !== cellId) {
                            if (skipMove(result, x2, y2, 2, cell.figure.type)) {
                                continue;
                            }

                            result[x2][y2].lvl2++;


                            mv2.move(map, true);

                            const mvs3 = fig.getMoves(map, x2, y2);

                            for (const mv3 of mvs3) {
                                const x3 = mv3.movement.rowTo;
                                const y3 = mv3.movement.columnTo;

                                if ((x3 !== rowId || y3 !== cellId) && (x3 !== x1 || y3 !== y1)) {
                                    if (skipMove(result, x3, y3, 3, cell.figure.type)) {
                                        continue;
                                    }

                                    result[x3][y3].lvl3++;
                                }
                            }

                            mv2.unmove(map);


                        }
                    }

                    mv1.unmove(map);
                }


            }

            cellId++;
        }

        rowId++
    }


    return result;
}