import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";
import {RookBlack} from "../figures/rook-black";
import {chessAlgo} from "../algo/chess";
import {BishopBlack} from "../figures/bishop-black";
import {PawnWhite} from "../figures/pawn-white";
import {KingWhite} from "../figures/king-white";

export class Test06262 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test 26.06 - 02';
    }

    init() {
        this.chessMap.moves = [
            new BishopBlack(),
        ];

        this.chessMap.cells[0][2].figure = undefined;
        this.chessMap.cells[0][3].figure = undefined;
        this.chessMap.cells[0][4].figure = undefined;
        this.chessMap.cells[0][6].figure = undefined;
        this.chessMap.cells[0][7].figure = undefined;
        this.chessMap.cells[1][1].figure = new BishopBlack();
        this.chessMap.cells[1][2].figure = new BishopBlack();
        this.chessMap.cells[1][3].figure = undefined;
        this.chessMap.cells[1][5].figure = undefined;
        this.chessMap.cells[1][7].figure = new KnightWhite();
        this.chessMap.cells[3][1].figure = new KnightBlack();
        this.chessMap.cells[3][2].figure = new PawnDark();
        this.chessMap.cells[3][8].figure = new QueenWhite();
        this.chessMap.cells[4][5].figure = new PawnWhite();
        this.chessMap.cells[4][6].figure = new PawnDark();
        this.chessMap.cells[4][8].figure = new PawnWhite();
        this.chessMap.cells[5][1].figure = new BishopWhite();
        this.chessMap.cells[5][3].figure = new PawnWhite();
        this.chessMap.cells[6][2].figure = undefined;
        this.chessMap.cells[6][3].figure = undefined;
        this.chessMap.cells[6][5].figure = undefined;
        this.chessMap.cells[6][6].figure = undefined;
        this.chessMap.cells[6][8].figure = undefined;
        this.chessMap.cells[7][1].figure = undefined;
        this.chessMap.cells[7][3].figure = undefined;
        this.chessMap.cells[7][4].figure = undefined;
        this.chessMap.cells[7][5].figure = undefined;
        this.chessMap.cells[7][7].figure = undefined;
        this.chessMap.cells[7][8].figure = undefined;
        this.chessMap.cells[7][2].figure = new KingWhite();
        this.chessMap.cells[7][6].figure = new RookWhite();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[4][5], 'b')) {
            return true;
        }

        return false;

    }
}