import {ChessMap} from "../../models/chess.map";
import {Movement} from "../../models/movement";
import {chessAlgo} from "../../algo/chess";
import {randomMove} from "./random-move";
import {isAttackingKing} from "./attack";
import {E_MOVE_BEAT_PRICE, figurePrice, isSorted} from "./price";
import {
    countCanMove,
    countCanMoveDistinct, countKingAttackersStream,
    countKingDefenders,
    countPotential,
    countPotentialLvl2,
    countRookOpen, countType
} from "./count";
import {distanceKingHeightFromStart} from "./distance";
import {PawnDark} from "../../figures/pawn-dark";
import {PawnWhite} from "../../figures/pawn-white";

let results: Movement[] = [];
let mxResult = 0;
export let lastMove = '';

export const setLastMove = (_lastMove: string) => {
    lastMove = _lastMove;
}

const checkResult = (map: ChessMap, moves: Movement[], keys: string[], attackers: boolean[]) => {

    let price = 0;

    price -= countPotential(map, !map.currentMoveBlack) * E_MOVE_BEAT_PRICE.UNDER_ATTACK_MULT;
    price -= countPotentialLvl2(map, !map.currentMoveBlack, E_MOVE_BEAT_PRICE.QUEEN_MN, E_MOVE_BEAT_PRICE.SECOND_BEAT_K) * E_MOVE_BEAT_PRICE.UNDER_ATTACK_MULT2;
    price += countPotential(map, map.currentMoveBlack) * E_MOVE_BEAT_PRICE.UNDER_ATTACK_EN_MULT;
    price += countPotentialLvl2(map, map.currentMoveBlack, 1, E_MOVE_BEAT_PRICE.SECOND_BEAT_K) * E_MOVE_BEAT_PRICE.UNDER_ATTACK_EN_MULT2;
    price += countCanMove(map, map.currentMoveBlack) * E_MOVE_BEAT_PRICE.MOVE_PRICE;
    price += countCanMoveDistinct(map, map.currentMoveBlack) * E_MOVE_BEAT_PRICE.MOVE_PRICE_DISTINCT;
    price += countCanMoveDistinct(map, !map.currentMoveBlack) * E_MOVE_BEAT_PRICE.MOVE_PRICE_COUNT;
    price += isSorted([lastMove, ...keys]) ? E_MOVE_BEAT_PRICE.SORT_PRICE : 0;
    price -= Math.pow(E_MOVE_BEAT_PRICE.MOVE_KING_POS, distanceKingHeightFromStart(map, map.currentMoveBlack) / 2);
    price += moves.length * E_MOVE_BEAT_PRICE.LENGTH_PRICE;
    price += countRookOpen(map, map.currentMoveBlack) * E_MOVE_BEAT_PRICE.ROOK_OPEN;
    price += countKingDefenders(map, map.currentMoveBlack) * E_MOVE_BEAT_PRICE.KING_DEFS;
    price += countKingAttackersStream(map, map.currentMoveBlack) * E_MOVE_BEAT_PRICE.ATTACKER_STREAM;

    let id = 0;
    for (const move of moves) {
        if (attackers[id]) {
            price += E_MOVE_BEAT_PRICE.KILL_ATTACKER;
        }

        const key = move.moveKilledFigure();

        if (key) {
            let killedPrice = figurePrice[key];
            let killerPrice = figurePrice[keys[id]];

            let lastRow = (map.currentMoveBlack ? (new PawnDark()) : (new PawnWhite())).moveVector === -1 ? 6 : 1;

            if (move.movement.rowTo === lastRow && key === 'p') {
                killedPrice = figurePrice['q'];
            }

            price += killedPrice > killerPrice ? E_MOVE_BEAT_PRICE.KILL_HARD :
                killerPrice === killedPrice ? E_MOVE_BEAT_PRICE.KILL_SAME :
                    E_MOVE_BEAT_PRICE.KILL_LOW;


            const countKilled = countType(map, key, !map.currentMoveBlack);
            const countKiller = countType(map, keys[id], map.currentMoveBlack);

            price += !countKilled && countKiller > 1 ? E_MOVE_BEAT_PRICE.KILL_LAST : 0;
        }

        id++;
    }

    if (price > mxResult) {
        mxResult = price;

        results = [moves[0]];
    } else if (price === mxResult) {
        results.push(moves[0]);
    }


    return price;
}


export const tryBeatOrMove = (map: ChessMap, step = 1): Movement | null => {
    // can kill step 1
    results = [];
    mxResult = -1e9;

    // lvl 1
    const mv1 = chessAlgo.getMoves(map);

    for (const m1 of mv1) {
        const t1 = map.cells[m1.movement.rowFrom][m1.movement.columnFrom].figure?.type as string;

        const a1 = isAttackingKing(map, m1.movement.rowTo, m1.movement.columnTo);

        m1.move(map);

        checkResult(map, [m1], [t1], [a1]);

        const mv2 = chessAlgo.getMoves(map);

        for (const m2 of mv2) {
            const t2 = map.cells[m2.movement.rowFrom][m2.movement.columnFrom].figure?.type as string;

            const a2 = isAttackingKing(map, m2.movement.rowTo, m2.movement.columnTo);

            m2.move(map);

            checkResult(map, [m1, m2], [t1, t2], [a1, a2]);

            const mv3 = chessAlgo.getMoves(map);

            for (const m3 of mv3) {

                const t3 = map.cells[m3.movement.rowFrom][m3.movement.columnFrom].figure?.type as string;

                const a3 = isAttackingKing(map, m3.movement.rowTo, m3.movement.columnTo);

                m3.move(map);

                checkResult(map, [m1, m2, m3], [t1, t2, t3], [a1, a2, a3]);

                m3.unmove(map);

            }

            m2.unmove(map);
        }

        m1.unmove(map);
    }


    const move = randomMove(results);

    if (move) {
        lastMove = map.cells[move.movement.rowFrom][move.movement.columnFrom].figure?.type as string;
    }


    return move;
}
