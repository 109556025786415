import {Bishop} from "./bishop";
import img from './images/wbishop.svg'


export class BishopWhite extends Bishop {


    constructor() {
        super(img, false);
    }


}