
import img from './images/bknight.svg'
import {Knight} from "./knight";


export class KnightBlack extends Knight {


    constructor() {
        super(img, true);
    }


}