import {Figure} from "../models/figure";
import {ChessMap} from "../models/chess.map";
import {Movement} from "../models/movement";
import {RookBlack} from "./rook-black";
import {QueenBlack} from "./queen-black";
import {KingBlack} from "./king-black";
import {BishopBlack} from "./bishop-black";
import {KnightBlack} from "./knight-black";
import {RookWhite} from "./rook-white";
import {QueenWhite} from "./queen-white";
import {KnightWhite} from "./knight-white";
import {BishopWhite} from "./bishop-white";


export class Pawn extends Figure {
    moves = 0;
    moveVector = 0;

    moveStep = 0;
    prevMoveStep = 0;

    constructor(public img: string, public isBlack: boolean) {
        super(img, isBlack, 'p');
    }

    onMove(step: number) {
        this.moves++;

        this.prevMoveStep = this.moveStep;

        this.moveStep = step;
    }

    onUnMove() {
        this.moves--;

        this.moveStep = this.prevMoveStep;
        this.prevMoveStep = 0;
    }

    getSafeMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {

        const movements: Movement[] = [];


        for (let dy = -1; dy <= 1; dy++) {
            if (this.canMove(map, fromX + this.moveVector, fromY + dy, !this.isBlack)) {
                if (!dy && map.cells[fromX + this.moveVector][fromY + dy].figure) continue;
                if (dy && !map.cells[fromX + this.moveVector][fromY + dy].figure) continue;

                if (fromX + this.moveVector === 0 || fromX + this.moveVector === 7) {
                    const nwFigures = this.isBlack ?
                        [new RookBlack(), new QueenBlack(), new KnightBlack(), new BishopBlack()]
                        : [new RookWhite(), new QueenWhite(), new KnightWhite(), new BishopWhite()];

                    for (const figure of nwFigures) {

                        const toX = fromX + this.moveVector;
                        const toY = fromY + dy;

                        if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack === this.isBlack) {
                            movements.push(new Movement({
                                rowFrom: fromX,
                                columnFrom: fromY,
                                rowTo: fromX + this.moveVector,
                                columnTo: fromY + dy,
                                transformTo: figure,
                            }));
                        }
                    }
                } else {

                    const toX = fromX + this.moveVector;
                    const toY = fromY + dy;

                    if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack === this.isBlack) {
                        movements.push(new Movement({
                            rowFrom: fromX,
                            columnFrom: fromY,
                            rowTo: fromX + this.moveVector,
                            columnTo: fromY + dy,
                        }));
                    }
                }
            }
        }

        return movements;
    }


    getAttackMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {

        const movements: Movement[] = [];


        for (let dy = -1; dy <= 1; dy++) {
            if (this.canMove(map, fromX + this.moveVector, fromY + dy, this.isBlack)) {
                if (!dy && map.cells[fromX + this.moveVector][fromY + dy].figure) continue;
                if (dy && !map.cells[fromX + this.moveVector][fromY + dy].figure) continue;

                if (fromX + this.moveVector === 0 || fromX + this.moveVector === 7) {
                    const nwFigures = this.isBlack ?
                        [new RookBlack(), new QueenBlack(), new KnightBlack(), new BishopBlack()]
                        : [new RookWhite(), new QueenWhite(), new KnightWhite(), new BishopWhite()];

                    for (const figure of nwFigures) {

                        const toX = fromX + this.moveVector;
                        const toY = fromY + dy;

                        if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack !== this.isBlack) {
                            movements.push(new Movement({
                                rowFrom: fromX,
                                columnFrom: fromY,
                                rowTo: fromX + this.moveVector,
                                columnTo: fromY + dy,
                                transformTo: figure,
                            }));
                        }
                    }
                } else {

                    const toX = fromX + this.moveVector;
                    const toY = fromY + dy;

                    if (map.cells[toX][toY].figure && map.cells[toX][toY].figure?.isBlack !== this.isBlack) {
                        movements.push(new Movement({
                            rowFrom: fromX,
                            columnFrom: fromY,
                            rowTo: fromX + this.moveVector,
                            columnTo: fromY + dy,
                        }));
                    }
                }
            }
        }

        return movements;
    }

    getMoves(map: ChessMap, fromX: number, fromY: number): Movement[] {

        const movements: Movement[] = [];


        for (let dy = -1; dy <= 1; dy++) {
            if (this.canMove(map, fromX + this.moveVector, fromY + dy, this.isBlack)) {
                if (!dy && map.cells[fromX + this.moveVector][fromY + dy].figure) continue;
                if (dy && !map.cells[fromX + this.moveVector][fromY + dy].figure) continue;

                if (fromX + this.moveVector === 0 || fromX + this.moveVector === 7) {
                    const nwFigures = this.isBlack ?
                        [new RookBlack(), new QueenBlack(), new KnightBlack(), new BishopBlack()]
                        : [new RookWhite(), new QueenWhite(), new KnightWhite(), new BishopWhite()];

                    for (const figure of nwFigures) {

                        movements.push(new Movement({
                            rowFrom: fromX,
                            columnFrom: fromY,
                            rowTo: fromX + this.moveVector,
                            columnTo: fromY + dy,
                            transformTo: figure,
                        }));
                    }

                } else {
                    movements.push(new Movement({
                        rowFrom: fromX,
                        columnFrom: fromY,
                        rowTo: fromX + this.moveVector,
                        columnTo: fromY + dy,
                    }));
                }
            }
        }

        if (!this.moves && this.canMove(map, fromX + this.moveVector * 2, fromY, this.isBlack)) {
            if (!map.cells[fromX + this.moveVector][fromY].figure && !map.cells[fromX + this.moveVector * 2][fromY].figure) {

                movements.push(new Movement({
                    rowFrom: fromX,
                    columnFrom: fromY,
                    rowTo: fromX + this.moveVector * 2,
                    columnTo: fromY,
                }));
            }
        }

        for (let dy = -1; dy <= 1; dy += 2) {
            if (this.canMove(map, fromX + this.moveVector, fromY + dy, this.isBlack)) {
                if (!map.cells[fromX][fromY + dy].figure ||
                    fromX !== 3 - (this.moveVector > 0 ? -1 : 0) ||
                    map.cells[fromX][fromY + dy].figure?.type !== 'p' ||
                    map.cells[fromX][fromY + dy].figure?.isBlack === this.isBlack ||
                    (map.cells[fromX][fromY + dy].figure as Pawn).moves !== 1) {
                    continue;
                }

                if (map.moveStep - (map.cells[fromX][fromY + dy].figure as Pawn).moveStep > 1) {
                    continue;
                }

                movements.push(new Movement({
                    rowFrom: fromX,
                    columnFrom: fromY,
                    rowTo: fromX + this.moveVector,
                    columnTo: fromY + dy,
                    dropRow: fromX,
                    dropColumn: fromY + dy,
                }));
            }
        }

        return movements;
    }
}