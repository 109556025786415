
import img from './images/wqueen.svg'
import {Queen} from "./queen";


export class QueenWhite extends Queen {


    constructor() {
        super(img, false);
    }


}