
import img from './images/brook.svg'
import {Rook} from "./rook";


export class RookBlack extends Rook {


    constructor() {
        super(img, true);
    }


}