import {BaseTest, IBot} from "./base-test";
import {KingBlack} from "../figures/king-black";
import {QueenBlack} from "../figures/queen-black";
import {PawnDark} from "../figures/pawn-dark";
import {QueenWhite} from "../figures/queen-white";
import {KnightBlack} from "../figures/knight-black";
import {KnightWhite} from "../figures/knight-white";
import {Bishop} from "../figures/bishop";
import {BishopWhite} from "../figures/bishop-white";
import {RookWhite} from "../figures/rook-white";

export class TestAttackFigures6 extends BaseTest {

    constructor(bot: IBot) {
        super(bot);
    }

    static getName() {
        return 'Test attack priority 6';
    }

    init() {
        this.chessMap.moves = [
          new QueenBlack(),
          new QueenBlack(),
        ];

        this.chessMap.cells[4][4].figure = new QueenBlack();

        this.chessMap.currentMoveBlack = true;
    }

    check() {
        if (this.isFigure(this.chessMap.cells[7][5], 'q')) {
            return true;
        }

        return false;

    }
}