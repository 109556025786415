
import {Figure} from "./figure";


export enum ECell {
    OUTBOARD = 1,
    DARK = 2,
    LIOHT = 3,
}


export class Cell {

    figure?: Figure = undefined;


    constructor(public label: string,
                public type: ECell) {
    }


}